import { BrowserRouter, Route, Routes } from "react-router-dom";

import "./styles.css";
import Message from "./Message";
import BarReceipt from "./BarReceipt";

export default function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Message />} />
        <Route path="/bar-receipt" element={<BarReceipt />} />
      </Routes>
    </BrowserRouter>
  );
}
