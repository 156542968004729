import { useEffect, useState } from "react";

import "./styles.css";
import EventLogo from "./EventLogo";

export default function BarReceipt() {
  const [items, setItems] = useState([
    "Strawberry Mojito",
    "Virgin Strawberry Mojito",
    "Mojito",
    "Virgin Mojito",
    "Rum and Coke",
  ]);
  const [now, setNow] = useState(Date.now());
  const date = new Date(now);

  const getDateTime = (): string => {
    return date
      .toLocaleString("en-GB", {
        year: "2-digit",
        month: "2-digit",
        day: "2-digit",
        hour: "2-digit",
        minute: "2-digit",
        hour12: false,
      })
      .replace(",", "");
  };

  useEffect(() => {
    setInterval(() => {
      setNow(Date.now());
    }, 1000);
  }, []);

  return (
    <div className="App">
      <div className="bar-header">
        <EventLogo />
      </div>

      <div className="bar-order-time">
        <span>{getDateTime()}</span>
      </div>

      <ul className="bar-items">
        {items.map((item) => (
          <li className="bar-item" key={item}>
            <span>
              <span>1</span>
              {item}
            </span>
            <span
              onClick={() => {
                setItems(items.filter((i) => i !== item));
              }}
            >
              £0.00
            </span>
          </li>
        ))}
      </ul>

      <div className="bar-total">
        <span>Total Amount</span>
        <span>£0.00</span>
      </div>
    </div>
  );
}
